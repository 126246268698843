import { getApiServerBaseUrl } from "../env"
import { isUndefinedOrNull } from "../variable"

const apiBaseUrl = getApiServerBaseUrl()

export const getLogoutApiUrl = () => `${apiBaseUrl}/auth/user/logout`

export const getStrapiAllApiUrl = (resource: string, populate: string) =>
	`${apiBaseUrl}/strapi/all?resource=${resource}&populate=${populate}`

export const getStrapiCustomFilterApiUrl = (
	resource: string,
	filterByKey: string,
	filterValue: string | number,
) =>
	`${apiBaseUrl}/strapi/customQuery?resource=${resource}&query=?filters[${filterByKey}][$eq]=${filterValue}%26populate=deep`

export function getStrapiUrlForCustomFields(
	resource: string,
	fields: string[],
	filterKey: string | null,
	filterValue: string | null,
) {
	let customStrapiUrl = `${apiBaseUrl}/strapi/all?resource=${resource}&populate=%26fields=*`

	if (!isUndefinedOrNull(filterKey) && !isUndefinedOrNull(filterValue)) {
		customStrapiUrl = `${apiBaseUrl}/strapi/customQuery?resource=${resource}&query=?filters%5B${filterKey}%5D%5B$eq%5D=${filterValue}%26fields=*`
	}

	for (let i = 0; i < fields.length; i += 1) {
		customStrapiUrl += `%26populate%5B${i}%5D=${fields[i]}`
	}

	return customStrapiUrl
}
