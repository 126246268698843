import { getImagesCdnUrl } from "@common/utils/env"

export const rfqPaymentModes = {
	upi: "UPI",
	netbanking: "NET_BANKING",
	neft: "BANK_TRANSFER",
}

export const rfqPaymentStatus = {
	pending: "PENDING",
	progress: "PROGRESS",
	success: "SUCCESS",
	failed: "FAILED",
}

export const rfqInvestmentKeys = {
	cart: "RFQ_INVESTMENT_DETAILS_IN_CART",
}

export const upiValidRegex = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/

export const rfqImages = {
	statusbar: `${getImagesCdnUrl()}/RFQ/PendingIcon.svg`,
	timerClock: `${getImagesCdnUrl()}/RFQ/clock.svg`,
	factIcon: `${getImagesCdnUrl()}/RFQ/FactQuestion.svg`,
	progressCircle: `${getImagesCdnUrl()}/RFQ/ProgressCircle.svg`,
	amountIcon: `${getImagesCdnUrl()}/RFQ/AmountIcon.svg`,
	alertIcon: `${getImagesCdnUrl()}/RFQ/AlertIcon.svg`,
	stepInfoIcon: `${getImagesCdnUrl()}/RFQ/StepInfoIcon.svg`,
	spinnerIcon: `${getImagesCdnUrl()}/RFQ/Spinner.svg`,
	sebiLogo: `${getImagesCdnUrl()}/RFQ/SEBILogo.svg`,
	fundsInfo: `${getImagesCdnUrl()}/RFQ/FundsInfo.svg`,
	neftConfirmed: `${getImagesCdnUrl()}/RFQ/NEFTConfirmedIcon.svg`,
	neftCashIcon: `${getImagesCdnUrl()}/RFQ/NEFTCashIcon.svg`,
	paymentStatus: {
		success: `${getImagesCdnUrl()}/RFQ/PaymentStatusSuccess.svg`,
		pending: `${getImagesCdnUrl()}/RFQ/PaymentStatusPending.svg`,
		failed: `${getImagesCdnUrl()}/RFQ/PaymentStatusFailed.svg`,
	},
}

export const rfqProcessingFacts = [
	{
		id: 1,
		text: "It all started with food: The first known bond in history was guaranteed by grains of corn.",
	},
	{
		id: 2,
		text: "Taking the legacy forward: Bonds were first issued in 2400 BC in modern-day Iraq.",
	},
	{
		id: 3,
		text: "Losing sleep for returns: In 1992, Investors slept outside offices to collect the application forms for the Mastergain 92 equity fund. ",
	},
	{
		id: 4,
		text: "Not just a piece of paper: Indian notes are actually made with cotton, plant balsam and silk.",
	},
	{
		id: 5,
		text: "Hidden Costs: The cost of minting a ₹1 coin is actually ₹1.11.",
	},
	{
		id: 6,
		text: "First Foreign Trip: Bank Of India was the first Indian bank to open a branch outside India (London) in 1946.",
	},
	{
		id: 7,
		text: "Notes of ₹5,000 and ₹10,000 have circulated in India. Imagine looking for a change without UPI.",
	},
	{
		id: 8,
		text: "In search of Gold: Just 4 states (Karnataka, Tamil Nadu, Andhra Pradesh and Kerala) create 40% of India’s demand for Gold.",
	},
	{
		id: 9,
		text: "Gold is a gift from the universe: All gold found today came from meteorites that bombarded Earth 200 million years ago.",
	},
	{
		id: 10,
		text: "Banking beyond borders: RBI was also the central bank for Myanmar (till 1947) and Pakistan (till 1948).",
	},
	{
		id: 11,
		text: "In the 1980s, settling trades on the stock market took a month. Thank you, internet.",
	},
]

export const rfqButtonIDConstants = {
	rfqOrderSummary: {
		back: "RFQ_ORDER_SUMMARY-ARROW-BACK",
		net_banking: "RFQ_ORDER_SUMMARY-PAYMENT METHOD-NETBANKING",
		upi: "RFQ_ORDER_SUMMARY-PAYMENT METHOD-UPI",
		neft: "RFQ_ORDER_SUMMARY-PAYMENT METHOD-NEFT/RTGS",
		proceedToPayment: "RFQ_ORDER_SUMMARY-CTA-PROCEED_TO_PAYMENT",
		neft_go_to_orders: "RFQ_ORDER_SUMMARY_NEFT/RTGS-CTA-GO_TO_ORDERS",
		verify: "RFQ_ORDER_SUMMARY-UPI_METHOD-VERIFY",
		view_neft_details: "RFQ_ORDER_SUMMARY-CTA-VIEW_DETAILS_RTGS",
		neft_beneficiary_added: "RFQ_ORDER_NEFT_RTGS-CHECK_BOX-BENEFICIARY_ADDED",
		neft_confirm: "RFQ_ORDER_NEFT_RTGS-CTA-CONFIRM_ORDER",
		upi_go_to_orders: "RFQ_ORDER_SUMMARY-CTA-ORDER",
	},
	rfqStatusBar: {
		homePage: "HOME_PAGE-CTA-COMPLETE_INVESTMENT",
		dashboard: "DASHBOARD-CTA-COMPLETE_INVESTMENT",
	},
	rfqOrderProcessing: {
		try_again: "RFQ_LOADER_SCREEN-CTA-TRY_AGAIN",
	},
	rfqPaymentStatusPage: {
		success: "RFQ_PAYMENT_SUCCESS-CTA-GO_TO_ORDERS",
		pending: "RFQ_PAYMENT_PENDING-CTA-GO_TO_ORDERS",
		failure: "RFQ_PAYMENT_FAILURE-CTA-RETRY_PAYMENT",
	},
	ordersPage: {
		ongoing_orders: "ORDERS_PAGE-SECTION-ONGOING_ORDERS",
		settled_orders: "ORDERS_PAGE-SECTION-SETTLED ORDERS",
		failed_retry: "ORDERS_PAGE-CTA-FAILED_RETRY",
		pending_retry: "ORDERS_PAGE-CTA-PENDING_RETRY",
		order_details_arrow: "ORDERS_PAGE-DOWN_ARROW-DETAILS",
	},
}

const imagePath = `${getImagesCdnUrl()}/payments`

export const rfqPGImages = {
	paymentStatus: {
		successMob: `${imagePath}/SuccessMweb.svg`,
		successWeb: `${imagePath}/SuccessWeb.svg`,
		progressMob: `${imagePath}/ProgressMweb.svg`,
		progressWeb: `${imagePath}/ProgressWeb.svg`,
		failedMob: `${imagePath}/FailedMweb.svg`,
		failedWeb: `${imagePath}/FailedWeb.svg`,
	},
}
export const apiErrorTypes = {
	maintenance: "UNDER_MAINTENANCE",
}
